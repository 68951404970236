import React, { useEffect, useState } from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import { useNavigate, useSearchParams } from 'react-router-dom';
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useIsAuthenticated,
} from '@azure/msal-react';

import { api } from '../../api';
import { Card } from '../../components/on-boarding/Card';
import { fetchSpaces } from '../../features/spaces/stores/space.slice';
import { authStore } from '../../core/stores/auth-store';
import { OnBoardingLayout } from '../on-boarding/on-boarding.layout';
import { fetchCurrentUser } from '../../features/user/user.slice';
import { Space } from '../../app/entities/space';
import { SelectSpace } from './components/select-space';
import { CreateSpace } from './components/create-space';
import { MicrosoftAuth } from './components/microsoft-auth';
import { catchError } from '../../core/catch-error';
import { PageLoader } from '../../app/components/page-loader';
import {
  MarketplaceSubscriptionDetails,
  SubscriptionDetailsPage,
} from './components/subscription-details';

export const MarketplaceLandingPage: React.FC = () => {
  const navigate = useNavigate();
  const [isReady, setIsReady] = useState(false);
  const [selectedSpace, setSelectedSpace] = useState<Space>();
  const [showCreateSpace, setShowCreateSpace] = useState<boolean>(false);
  const [spaces, setSpaces] = useState<Space[]>();
  const [subscriptionDetails, setSubscriptionDetails] = useState<MarketplaceSubscriptionDetails>();
  const [searchParams] = useSearchParams();
  const isAuthenticatedOnMicrosoft = useIsAuthenticated();

  const purchaseToken = searchParams.get('token');

  useEffect(() => {
    if (!purchaseToken) {
      navigate('/');
      return;
    }

    initiateData();
  }, []);

  const resolveSubscription = async () => {
    try {
      const res = await api.post(`/payment/subscriptions/resolve`, {
        purchaseToken,
      });

      if (!res || res.status !== 200) {
        navigate('/');
        return;
      }

      setSubscriptionDetails(res.data);
    } catch (err) {
      navigate('/');
    }
  };

  const populateSpaces = async () => {
    fetchCurrentUser().then(() => {
      fetchSpaces({ userId: authStore.currentUser!._id }).then((spacesRes) => {
        setSpaces(spacesRes);

        if (spacesRes.length === 1) {
          setSelectedSpace(spacesRes[0]);
        }

        if (!spacesRes.length) {
          setShowCreateSpace(true);
        }
      });
    });
  };

  const initiateData = async () => {
    try {
      await resolveSubscription();
      await populateSpaces();
    } finally {
      setIsReady(true);
    }
  };

  const activateSubscription = async (spaceId: string) => {
    try {
      const res = await api.post(`/payment/spaces/${spaceId}/subscriptions/activate`, {
        purchaseToken,
      });

      if (!res || res.status !== 200) {
        return;
      }

      const space = Space.getOne(spaceId);

      if (space) {
        space.subscription = res.data.subscription;
        space.storage = res.data.storage;
        space.seats = res.data.seats;
      }

      navigate(`/marketplace/thanks?spaceId=${space?._id}`);
    } catch (err) {
      catchError(err);
    }
  };

  if (!isAuthenticatedOnMicrosoft) {
    return (
      <UnauthenticatedTemplate>
        <OnBoardingLayout>
          <GlobalStyle />
          <MicrosoftAuth />
        </OnBoardingLayout>
      </UnauthenticatedTemplate>
    );
  }

  if (!isReady) {
    return <PageLoader />;
  }

  return subscriptionDetails ? (
    <AuthenticatedTemplate>
      <OnBoardingLayout>
        <GlobalStyle />

        <Container id="landing-page">
          <Card title="Activate your subscription">
            <SubscriptionDetailsPage subscriptionDetails={subscriptionDetails} />

            <Separator />

            {!showCreateSpace && (
              <SelectSpace
                spaces={spaces || []}
                setSelectedSpace={setSelectedSpace}
                selectedSpace={selectedSpace}
                setShowCreateSpace={setShowCreateSpace}
                activateSubscription={activateSubscription}
              />
            )}

            {showCreateSpace && (
              <CreateSpace
                setShowCreateSpace={setShowCreateSpace}
                activateSubscription={activateSubscription}
              />
            )}
          </Card>
        </Container>
      </OnBoardingLayout>
    </AuthenticatedTemplate>
  ) : (
    <></>
  );
};

const GlobalStyle = createGlobalStyle`
  html {
    background-color: var(--color-grayscale-eerie-black);
  }
`;

const Container = styled.div`
  max-width: 48rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 3.2rem;
`;

const Separator = styled.hr`
  border: 1px solid var(--color-grey-8);
`;
